import React, { useState } from "react";
import "./styles.css";
import Container from "../../../components/Container";
import api from "../../../services/api";

function ClientForm() {
  const [clientData, setClientData] = useState({
    nomeEmpresa: "",
    cnpj: "",
    unidade: "",
  });

  // Atualiza o valor do campo quando o usuário digita
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  // Envia os dados do formulário ao backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/api/clientes", clientData);
      alert(response.data.message);
      console.log("Resposta do servidor:", response.data);
    } catch (error) {
      console.error("Erro ao enviar dados ao backend:", error);
      alert(
        "Erro ao cadastrar cliente. Verifique o console para mais detalhes."
      );
    }
  };

  return (
    <Container>
      <div className="client-form-content">
        <div className="client-form-container">
          <h2>Cadastrar Novo Cliente</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nome da Empresa:</label>
              <input
                type="text"
                name="nomeEmpresa"
                value={clientData.nomeEmpresa}
                onChange={handleChange}
                placeholder="ex: Petrobras"
                required
              />
            </div>

            <div className="form-group">
              <label>CNPJ:</label>
              <input
                type="text"
                name="cnpj"
                value={clientData.cnpj}
                onChange={handleChange}
                placeholder="ex: 01.230.950/0001-00"
                required
              />
            </div>

            <div className="form-group">
              <label>Unidade:</label>
              <input
                type="text"
                name="unidade"
                value={clientData.unidade}
                onChange={handleChange}
                placeholder="ex: PB-LOG"
                required
              />
            </div>

            <button type="submit" className="btn-primary">
              Cadastrar Cliente
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default ClientForm;
