import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import { Link } from "react-router-dom";
import api from "../../../services/api";

function ClientDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [valves, setValves] = useState([]); // Estado para armazenar válvulas vinculadas
  const [isEditing, setIsEditing] = useState(false);

  // Buscar detalhes do cliente
  const fetchClientDetails = useCallback(async () => {
    try {
      const response = await api.get(`/api/clientes/${id}`);
      setClient({
        ...response.data,
        nomeUsuario: response.data.nomeUsuario || "N/A",
        loginUsuario: response.data.loginUsuario || "N/A",
        senhaUsuario: "",
      });
    } catch (error) {
      console.error("Erro ao buscar detalhes do cliente:", error);
    }
  }, [id]);

  // Buscar válvulas vinculadas ao cliente
  const fetchValves = useCallback(async () => {
    try {
      const response = await api.get(`/api/valvulas-seepil/cliente/${id}`);
      console.log(response);
      setValves(response.data);
    } catch (error) {
      console.error("Erro ao buscar válvulas do cliente:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchClientDetails();
    fetchValves();
  }, [fetchClientDetails, fetchValves]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const updatedClient = { ...client };
      if (!updatedClient.senha) delete updatedClient.senha;

      await api.put(`/api/clientes/${id}`, updatedClient);
      alert("Cliente atualizado com sucesso!");
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir este cliente?")) {
      try {
        await api.delete(`/api/clientes/${id}`);
        alert("Cliente deletado com sucesso!");
        navigate("/admin/clientes");
      } catch (error) {
        console.error("Erro ao deletar cliente:", error);
        alert(
          "Erro ao deletar cliente. Verifique o console para mais detalhes."
        );
      }
    }
  };

  if (!client) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <div className="client-details-container">
        <h2>Detalhes do Cliente</h2>
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            name="nome"
            value={client.nome}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>CNPJ:</label>
          <input
            type="text"
            name="cnpj"
            value={client.cnpj}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Unidade:</label>
          <input
            type="text"
            name="unidade"
            value={client.unidade}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Nome do Usuário:</label>
          <input
            type="text"
            name="nomeUsuario"
            value={client.nomeUsuario || ""}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
      </div>

      <div className="valves-container">
        <h2>Válvulas Vinculadas</h2>
        {valves.length > 0 ? (
          <ul>
            {valves.map((valve) => (
              <li key={valve.id}>
                <Link to={`/admin/valvulas/${valve.id}`}>{valve.tag}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>Não há válvulas vinculadas a este cliente.</p>
        )}
      </div>

      <div className="button-group">
        {isEditing ? (
          <button onClick={handleSave}>Salvar Alterações</button>
        ) : (
          <button onClick={() => setIsEditing(true)}>Editar Cadastro</button>
        )}
        <button onClick={() => navigate("/admin/clientes")}>Voltar</button>
        <button onClick={handleDelete} className="btn-delete">
          Deletar Cliente
        </button>
      </div>
    </Container>
  );
}

export default ClientDetails;
