import Container from "../../../components/Container";
import { useState } from "react";
import api from "../../../services/api";

function UserForm() {
  const [userData, setUserData] = useState({
    nomeUsuario: "",
    loginUsuario: "", // login inserido manualmente pelo admin
    senhaUsuario: "", // senha inserida manualmente pelo admin
  });

  // Atualiza o valor do campo quando o usuário digita
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // Envia os dados do formulário ao backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/api/clientes", userData);
      alert(response.data.message);
      console.log("Resposta do servidor:", response.data);
    } catch (error) {
      console.error("Erro ao enviar dados ao backend:", error);
      alert(
        "Erro ao cadastrar cliente. Verifique o console para mais detalhes."
      );
    }
  };

  return (
    <Container>
      <div className="client-form-content">
        <div className="client-form-container">
          <h2>Cadastrar novo usuário</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nome Completo:</label>
              <input
                type="text"
                name="nomeUsuario"
                value={userData.nomeUsuario}
                onChange={handleChange}
                placeholder="ex: Carlos Garcia"
                required
              />
            </div>

            <div className="form-group">
              <label>Login do Usuário (e-mail) :</label>
              <input
                type="email"
                name="loginUsuario"
                value={userData.loginUsuario}
                onChange={handleChange}
                placeholder="ex: carlos@petrobras.com"
                required
              />
            </div>

            <div className="form-group">
              <label>Senha do Usuário:</label>
              <input
                type="password"
                name="senhaUsuario"
                value={userData.senhaUsuario}
                onChange={handleChange}
                placeholder="ex: pblog-01230950"
                required
              />
            </div>
            <button type="submit" className="btn-primary">
              Cadastrar Cliente
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default UserForm;
