import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import api from "../../../services/api";

function ValveDetails() {
  const { id } = useParams(); // Obtendo o ID da válvula pela URL
  const [valve, setValve] = useState(null);
  const [newFotosValvula, setNewFotosValvula] = useState([]);
  const [newFotosPlaqueta, setNewFotosPlaqueta] = useState([]);

  // Função para buscar os detalhes da válvula
  const fetchValveDetails = useCallback(async () => {
    try {
      const response = await api.get(`/api/valvulas-seepil/${id}`);
      setValve(response.data);
    } catch (error) {
      console.error("Erro ao buscar detalhes da válvula:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchValveDetails();
  }, [fetchValveDetails]);

  if (!valve) {
    return <div>Carregando...</div>;
  }

  // Garantindo que fotoValvula e fotoPlaquetaFabricante são arrays
  const fotosValvula = Array.isArray(valve.fotoValvula)
    ? valve.fotoValvula
    : [valve.fotoValvula].filter(Boolean);
  const fotosPlaqueta = Array.isArray(valve.fotoPlaquetaFabricante)
    ? valve.fotoPlaquetaFabricante
    : [valve.fotoPlaquetaFabricante].filter(Boolean);

  // Função para lidar com mudanças nos campos
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValve((prevValve) => ({
      ...prevValve,
      [name]: value,
    }));
  };

  // Função para salvar alterações
  const handleSave = async () => {
    try {
      await api.put(`/api/valvulas-seepil/${id}`, valve);
      alert("Válvula atualizada com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar válvula:", error);
    }
  };

  // Função para lidar com o upload de novas fotos
  const handleFileChange = (e, type) => {
    if (type === "valvula") {
      setNewFotosValvula([...e.target.files]);
    } else if (type === "plaqueta") {
      setNewFotosPlaqueta([...e.target.files]);
    }
  };

  // Função para enviar novas fotos
  const handleUpload = async (type) => {
    const formData = new FormData();
    const files = type === "valvula" ? newFotosValvula : newFotosPlaqueta;

    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      await api.post(`/api/valvulas-seepil/${id}/upload-${type}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Fotos carregadas com sucesso!");
      fetchValveDetails(); // Atualiza as informações da válvula
    } catch (error) {
      console.error("Erro ao carregar fotos:", error);
    }
  };

  return (
    <Container>
      <div className="valve-details-container">
        <h2>Detalhes da Válvula</h2>
        {/* Campos Editáveis */}
        <div className="form-group">
          <label>TAG:</label>
          <input
            type="text"
            name="tag"
            value={valve.tag}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Cliente ID:</label>
          <input
            type="text"
            name="cliente_id"
            value={valve.cliente_id}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Tipo de Válvula:</label>
          <input
            type="text"
            name="tipoValvula"
            value={valve.tipoValvula}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Fabricante:</label>
          <input
            type="text"
            name="fabricante"
            value={valve.fabricante}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Modelo:</label>
          <input
            type="text"
            name="modelo"
            value={valve.modelo}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Número de Série:</label>
          <input
            type="text"
            name="numeroSerie"
            value={valve.numeroSerie}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Local de Instalação:</label>
          <input
            type="text"
            name="localInstalacao"
            value={valve.localInstalacao}
            onChange={handleInputChange}
          />
        </div>

        {/* Seção para Fotos da Válvula */}
        <div className="form-group">
          <label>Fotos da Válvula:</label>
          <div className="gallery">
            {fotosValvula.map((foto, index) => (
              <img
                key={index}
                src={`http://localhost:3001/uploads/${foto}`}
                alt={`Foto Válvula ${index + 1}`}
                className="thumbnail"
                onClick={() =>
                  window.open(`http://localhost:3001/uploads/${foto}`, "_blank")
                }
              />
            ))}
          </div>
          <input
            type="file"
            multiple
            onChange={(e) => handleFileChange(e, "valvula")}
          />
          <button
            onClick={() => handleUpload("valvula")}
            className="btn-upload"
          >
            Enviar
          </button>
        </div>

        {/* Seção para Fotos Plaqueta do Fabricante */}
        <div className="form-group">
          <label>Fotos Plaqueta do Fabricante:</label>
          <div className="gallery">
            {fotosPlaqueta.map((foto, index) => (
              <img
                key={index}
                src={`http://localhost:3001/uploads/${foto}`}
                alt={`Foto Plaqueta ${index + 1}`}
                className="thumbnail"
                onClick={() =>
                  window.open(`http://localhost:3001/uploads/${foto}`, "_blank")
                }
              />
            ))}
          </div>
          <input
            type="file"
            multiple
            onChange={(e) => handleFileChange(e, "plaqueta")}
          />
          <button
            onClick={() => handleUpload("plaqueta")}
            className="btn-upload"
          >
            Enviar
          </button>
        </div>

        <button onClick={handleSave} className="btn-primary">
          Salvar Alterações
        </button>
      </div>
    </Container>
  );
}

export default ValveDetails;
