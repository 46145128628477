import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import api from "../../../services/api";

function ValveList() {
  const [valves, setValves] = useState([]);

  const fetchValves = async () => {
    try {
      const response = await api.get("/api/valvulas-seepil");
      setValves(response.data);
    } catch (error) {
      console.error("Erro ao buscar válvulas:", error);
    }
  };

  useEffect(() => {
    fetchValves();
  }, []);

  return (
    <Container>
      <div className="valve-list-container">
        <h2>Válvulas Cadastradas</h2>
        <table className="valve-table">
          <thead>
            <tr>
              <th>Ação</th>
              <th>TAG</th>
              <th>Nº Série</th>
              <th>Cliente</th>
              <th>Unidade</th>
              <th>Tipo de Válvula</th>
            </tr>
          </thead>
          <tbody>
            {valves.length > 0 ? (
              valves.map((valve) => (
                <tr key={valve.id}>
                  <td>
                    <Link to={`/admin/valvulas/${valve.id}`}>
                      <img
                        src="/assets/icon-show.png"
                        alt="Visualizar válvula"
                        title="Visualizar válvula"
                        className="view-icon"
                      />
                    </Link>
                  </td>
                  <td>{valve.tag}</td>
                  <td>{valve.numeroSerie}</td>
                  <td>{valve.nome}</td>
                  <td>{valve.unidade}</td>
                  <td>{valve.tipoValvula}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">Nenhuma válvula encontrada</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Container>
  );
}

export default ValveList;
