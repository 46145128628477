import React from 'react';
import './styles.css';


function Dashboard() {
  return (
    <div className="dashboard">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="logo">
          <img src="../assets/logo-seepil.png" alt="Seepil Logo" />
        </div>
        <ul className="sidebar-menu">
        <li>
            <img src="..\assets\icon-home-page.png" alt="Ícone Início" style={{ width: '13px', height: '13px', marginRight: '10px' }} />
            Início
          </li>
          <li>
            <img src="\assets\icon-copy.png" alt="Ícone Certificados" style={{ width: '13px', height: '13px', marginRight: '10px' }} />
            Certificados
          </li>
          <li>
            <img src="\assets\icon-setting.png" alt="Ícone Meus Dados" style={{ width: '13px', height: '13px', marginRight: '10px' }}/>
            Meus Dados
          </li>
          <li>
            <img src="\assets\icon-chat.png" alt="Ícone Suporte"style={{ width: '13px', height: '13px', marginRight: '10px' }} />
            Suporte
          </li>
          <li>
            <img src="\assets\icon-log-out.png" alt="Ícone Logout"style={{ width: '13px', height: '13px', marginRight: '10px' }} />
            Logout
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="main-content">
        <header className="header">
          <input type="text" placeholder="Pesquisar..." />
          <div className="user-info">
            <span>Anna Adame</span>
            <img src="../assets/user-pic.png" alt="User Avatar" />
          </div>
        </header>

        <div className="content">
          <h1>Bem vindo ao PSV Seepil Care.</h1>
          <p>Utilize o menu ao lado para acesso às funções.</p>
          <div className="cert-info">
            Sua empresa possui <strong><span style={{ color: 'orange' }}>30 certificados</span></strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
