import React from "react";
import "./styles.css";

import Container from "../../../components/Container";

function AdminDashboard() {
  return (
    <Container>
      <div className="content">
        <h1>Bem-vindo a Seepil Valve Care</h1>
        <p>
          Selecione uma opção no menu à esquerda para criar ou listar seu
          cadastro de clientes e válvulas.
        </p>
      </div>
    </Container>
  );
}

export default AdminDashboard;
