import React, { useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import Container from "../../../components/Container";
import { Link } from "react-router-dom";
import api from "../../../services/api";

function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [valves, setValves] = useState([]); // Estado para armazenar válvulas vinculadas
  const [isEditing, setIsEditing] = useState(false);

  // Buscar detalhes do cliente

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const updatedClient = { ...client };
      if (!updatedClient.senha) delete updatedClient.senha;

      await api.put(`/api/clientes/${id}`, updatedClient);
      alert("Cliente atualizado com sucesso!");
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir este cliente?")) {
      try {
        await api.delete(`/api/clientes/${id}`);
        alert("Cliente deletado com sucesso!");
        navigate("/admin/clientes");
      } catch (error) {
        console.error("Erro ao deletar cliente:", error);
        alert(
          "Erro ao deletar cliente. Verifique o console para mais detalhes."
        );
      }
    }
  };

  if (!client) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <div className="client-details-container">
        <h2>Dados do Usuário</h2>
      </div>

      <div className="access-details-container">
        <h2>Dados de Acesso</h2>
        <div className="form-group">
          <label>Login:</label>
          <input
            type="text"
            name="loginUsuario"
            value={client.loginUsuario || ""}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="form-group">
          <label>Senha:</label>
          <input
            type="password"
            name="senhaUsuario"
            value={client.senhaUsuario || ""}
            onChange={handleInputChange}
            placeholder="Deixe em branco para não alterar"
            disabled={!isEditing}
          />
        </div>
      </div>

      <div className="valves-container">
        <h2>Válvulas Cadastradas</h2>
        {valves.length > 0 ? (
          <ul>
            {valves.map((valve) => (
              <li key={valve.id}>
                <Link to={`/admin/valvulas/${valve.id}`}>{valve.tag}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>Não há válvulas vinculadas a este usário.</p>
        )}
      </div>

      <div className="button-group">
        {isEditing ? (
          <button onClick={handleSave}>Salvar Alterações</button>
        ) : (
          <button onClick={() => setIsEditing(true)}>Editar Cadastro</button>
        )}
        <button onClick={() => navigate("/admin/clientes")}>Voltar</button>
        <button onClick={handleDelete} className="btn-delete">
          Deletar Cliente
        </button>
      </div>
    </Container>
  );
}

export default UserDetails;
