import Container from "../../../components/Container"; //puxa header e sidebar
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

function Users() {
  const [searchTerm, setSearchTerm] = useState("");
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);

  // Função para buscar os clientes do backend
  const fetchUsers = async () => {
    try {
      const response = await api.get("/api/usuarios");
      setUsers(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
    }
  };
  const handleChange = (e) => {
    filterUsers(e.target.value);
  };

  const filterUsers = async (clientId) => {
    try {
      const response = await api.get(`/api/usuarios?clientId=${clientId}`);
      setUsers(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await api.get("/api/clientes");
      setClients(response.data);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchClients();
  }, []);

  return (
    <Container>
      <div className="client-list-container">
        <h2>Usuários Cadastrados</h2>

        <select onChange={handleChange}>
          <option> Selecione o Cliente:</option>
          {clients.map((item) => (
            <option value={item.id}>
              {item.nome} - {item.unidade}
            </option>
          ))}
        </select>

        <table className="client-table">
          <thead>
            <tr>
              <th>Ação</th>
              <th>Nome</th>
              <th>Login</th>
              <th>Empresa</th>
              <th>Unidade</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((client) => (
                <tr key={client.id}>
                  <td style={{ width: "25px" }}>
                    <Link to={`/admin/usuarios/${client.id}`}>
                      <img
                        src="/assets/icon-show.png"
                        alt="Visualizar cliente"
                        title="Visualizar usuário"
                        className="view-icon"
                      />
                    </Link>
                  </td>
                  <td>{client.nome}</td>
                  <td>{client.login}</td>
                  <td>{client.nome}</td>
                  <td>{client.unidade}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Nenhum usuário encontrado</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Container>
  );
}

export default Users;
